class ActivityReportNew {
  $scope: any;
  $location: any;
  ActivityReportFactory: any;
  AssignmentFactory: any;
  AppFactory: any;
  TimeTrackingFactory: any;
  loaderFactory: any;
  ErrFactory: any;

  loaded: any;
  assignment: any;
  _startDate: any;
  _endDate: any;
  dailyReports: any;
  reportsNumber: any;
  selectedWeek: any;
  loading: any;
  #weeks = null;

  constructor ($scope, $location, ActivityReportFactory, AssignmentFactory, AppFactory, TimeTrackingFactory, LoaderFactory, ErrFactory) {
    Object.defineProperty(this, '$scope',                { value: $scope                });
    Object.defineProperty(this, '$location',             { value: $location             });
    Object.defineProperty(this, 'ActivityReportFactory', { value: ActivityReportFactory });
    Object.defineProperty(this, 'TimeTrackingFactory',   { value: TimeTrackingFactory   });
    Object.defineProperty(this, 'loaderFactory',         { value: LoaderFactory         });
    Object.defineProperty(this, 'ErrFactory',            { value: ErrFactory         });

    this.loaded = false;
    this.assignment = null;
    this._startDate = null;
    this._endDate = null;
    this.dailyReports = [];
    this.reportsNumber = 0;
    this.selectedWeek = null;

    if (AppFactory.isNetwork()) {
      this.loaderFactory.show();
      AssignmentFactory.sync()
      .then(() => {
        this.loaderFactory.hide();
        this.addWatcher();
      })
      .catch((err) => {
        if (err instanceof ErrFactory) err.notify();
        else console.error(err);
      });
    } else this.addWatcher();
  }

  get weekList () {
    return Array.from(this.#weeks || []).reverse();
  }

  addWatcher() {
    this.$scope.$watch( '$ctrl.assignment', (newVal, oldVal) => {
        if (newVal !== oldVal) {
          this.updateWeeks(oldVal);
          this.updateTTList();
        }
    }, true);
    this.loaded = true;
  };

  updateWeeks(firstUpdate = null) {
    let endJob = Weeks.getEndOfWeek( this.assignment.ends_at || Weeks.getEndOfWeek(new Date) );
    let startCurrentWeek = Weeks.getStartOfWeek(new Date);

    let start = new Date(Math.max(
      Weeks.getStartOfWeek(this.assignment.starts_at).valueOf(),
      Number(startCurrentWeek) + Weeks.getWeeksSize(startCurrentWeek,-6)
    ));

    let end = new Date(Math.min(
      +endJob,
      +Weeks.getEndOfWeek(new Date)
    ));

    this.#weeks = new Weeks(start, end);
    if (firstUpdate) this.selectedWeek = this.#weeks.getStartWeekByDate(new Date);
  }

  create () {
    this.updateTTList("create");
  }

  setStartEndDates(week) {
    this._startDate = new Date(Math.max(week, this.assignment.starts_at));

    let endCurrentWeek = Weeks.getEndOfWeek(week);
    this._endDate = new Date(Math.min(+endCurrentWeek, (this.assignment.ends_at || endCurrentWeek)));
    this._endDate = this._endDate.setHours(23, 59, 59);
  }

  updateTTList(marker = null) {
    if (this.assignment && this.selectedWeek) {
      this.setStartEndDates(this.selectedWeek);
      this.TimeTrackingFactory.getOwn()
      .then((list) => {
        this.dailyReports = list.filter(item => {
          if (item.status !== 3) return false;
          else if (item.assignmentId !== this.assignment.id) return false;
          else if (this._startDate > item.start_date || this._endDate < item.start_date) return false;
          else return true;
        });
        if(this.dailyReports.length) {
          this.reportsNumber = this.dailyReports.length;
        } else {
          this.reportsNumber = 0;
        }
        this.loading = false;
        this.$scope.$apply();
      })
      .then(()=> {
        if (marker == "create") {
          let defaultDaily = [{
            id: 1,
            project:    this.assignment.prefillProject && this.assignment.project,
            start_date: new Date(this._startDate.getFullYear(), this._startDate.getMonth(), this._startDate.getDate(),  8, 0, 0),
            end_date:   new Date(this._startDate.getFullYear(), this._startDate.getMonth(), this._startDate.getDate(), 16, 0, 0),
            pauses: [{
              start: new Date(this._startDate.getFullYear(), this._startDate.getMonth(), this._startDate.getDate(), 12,  0, 0),
              end:   new Date(this._startDate.getFullYear(), this._startDate.getMonth(), this._startDate.getDate(), 12, 30, 0),
            }]
          }];
          let ar = new this.ActivityReportFactory({
            assignment:      this.assignment,
            startDate:       this._startDate,
            endDate:         new Date(this._endDate),
            dailyReportList: this.dailyReports.length ? this.dailyReports : defaultDaily
          });

          ar.save()
          .then((ar) => {
            this.$location.path(`/activity-report/${ar.id}`);
            this.$scope.$apply();
          })
          .catch((err) => console.error(err));
        }
      })
      .catch((err) => console.error(err));
    } else {
      this.loading = true;
    }
  }


  back () {
    this.$location.path(`/activity-report/preselected`);
  }

}

window.app.component('activityReportNew', {
  template: require('scripts/components/time-trackings/activity-report-new/activity-report.html'),
  controller: ['$scope', '$location', 'ActivityReportFactory', 'AssignmentFactory', 'AppFactory', 'TimeTrackingFactory', 'LoaderFactory', 'ErrFactory', ActivityReportNew]
});
