class ArPhototn {
  $scope:                any;
  $compile:              any;
  api:                   any;
  activityReportFactory: any;
  appFactory:            any;
  authService:           any;
  loaderFactory:         any;
  errFactory:            any;
  notification:          any;
  config:                any;


  loaded:                  any;
  assignment:              any;
  selectedWeek:            any;
  external_employee_notes: string;
  loading:                 boolean;

  cameraPreview:           boolean;
  imageURI:                string;

  #weeks = null;

  constructor (API, ActivityReportFactory, AppFactory, AssignmentFactory, AuthService, LoaderFactory, ErrFactory, Notification, Config, $scope, $compile) {
    Object.defineProperties(this, {
      $scope:                { value: $scope                },
      $compile:              { value: $compile              },
      api:                   { value: API                   },
      activityReportFactory: { value: ActivityReportFactory },
      appFactory:            { value: AppFactory            },
      authService:           { value: AuthService           },
      loaderFactory:         { value: LoaderFactory         },
      errFactory:            { value: ErrFactory            },
      notification:          { value: Notification          },
      config:                { value: Config                },
    });

    this.loaded = false;
    this.assignment = null;
    this.selectedWeek = null;
    this.imageURI = null;
    this.external_employee_notes = null;

    if (this.appFactory.isNetwork()) {
      this.loaderFactory.show();
      AssignmentFactory.sync()
      .then(() => {
        this.loaderFactory.hide();
        this.addWatcher();
        this.loaded = true;
      })
      .catch((err) => {
        if (err instanceof ErrFactory) err.notify();
        else console.error(err);
      });
    } else this.addWatcher();

    let el = document.querySelectorAll('input[maxlength],textarea[maxlength]');
    el.forEach(e => {
      e.addEventListener('keyup', function(event) {
        this.value = this.value.substr(0, this.getAttribute("maxlength"));
        this.onkeyup && this.onkeyup();
      });
    });
  }

  get weekList() {
    return this.#weeks && Array.from(this.#weeks).reverse() || [];
  }

  private addWatcher(): void {
    this.$scope.$watch( '$ctrl.assignment', (newVal, oldVal) => {
        if (newVal !== oldVal) {
          this.changeJob();
        }
    }, true);
  };

  create(): void {
    if (!this.selectedWeek) return this.notification.alert({ title: 'note', desc: 'activityReport.select-cw' });
    if (!this.imageURI)     return this.notification.alert({ title: 'note', desc: 'activityReport.addPhoto' });

    this.loading = true;
    this.loaderFactory.show();
    Promise.resolve()
    .then(() => this.authService.loadUser())
    .then(user => {
      let endDate = Weeks.getEndOfWeek(this.selectedWeek);
      let ar = new this.activityReportFactory({
        assignment: this.assignment,
        startDate: (this.selectedWeek > this.assignment.starts_at) ? this.selectedWeek : this.assignment.starts_at,
        endDate: (endDate < this.assignment.ends_at) ? endDate : this.assignment.ends_at,
        attachment: this.imageURI,
        readyToSubmit: true,
        external_employee_notes: this.external_employee_notes,
        status: 1
      });
      return this.checkMileageMoney(user, ar);
    })
    .then(ar => ar.save()
      .then(() => ar.submit())
      .then(() => ar.remove())
    )
    .then(() => {
      this.loaderFactory.hide();
      return this.notification.alert({
        title: 'activityReport.successfulTitle',
        desc: 'activityReport.successfulMessage'
      }, this.back());
    })
    .catch((err) => {
      this.loaderFactory.hide();
      if (err instanceof this.errFactory) err.notify(() => this.loading = false);
      else {
        console.error(err);
        this.loading = false;
      }
    });
  }

  changeJob(assignment = this.assignment): void {
    this.selectedWeek = null;
    let endJob = Weeks.getEndOfWeek( assignment.ends_at || Weeks.getEndOfWeek(new Date) );
    let startCurrentWeek = Weeks.getStartOfWeek(new Date);

    let start = new Date(Math.max(
      Weeks.getStartOfWeek(assignment.starts_at).valueOf(),
      Number(startCurrentWeek) + Weeks.getWeeksSize(startCurrentWeek,-6)
    ));

    let end = new Date(Math.min(
      +endJob,
      +Weeks.getEndOfWeek(new Date)
    ));

    this.#weeks = new Weeks(start, end);
  };

  capturePhoto() {
    // if (window.cordova) this.cameraPreview = true;
    if (window.cordova) this.devicePhoto();
    else if (this.config.hostName && this.config.hostName === 'staging') this.fakeImage();
  }

  private devicePhoto(): void {
    this.loaderFactory.show();
    (new Promise((res,rej) => navigator.camera.getPicture(res, rej, {
      quality:            80,
      destinationType:    Camera.DestinationType.DATA_URL,
      encodingType:       Camera.EncodingType.JPEG,
      correctOrientation: true,
      allowEdit:          false,
      saveToPhotoAlbum:   false
    })))
    .then((base64) => {
      this.imageURI = `data:image/jpeg;base64,${base64}`;
      this.$scope.$apply();
    })
    .catch((err) => {
      console.error(err);
    })
    .then(() => {
      this.loaderFactory.hide();
    });
  }

  onConfirm(image): void {
    this.cameraPreview = false
    this.imageURI      = image;
  }

  private fakeImage(): Promise<void> {
    this.loaderFactory.show();
    return this.api.getRandomImage(400, 600)
    .then((data) => {        
      this.imageURI = data;
      this.$scope.$apply();
    })
    .catch((err) => {
      console.error(err);
    })
    .then(() => {
      this.loaderFactory.hide();
    });
  }

  back(): void {
    this.appFactory.goBack();
  }

  async checkMileageMoney(user, ar) {
    if (user && user.address && ar.assignment.mileage_money_time_frames && ar.assignment.mileage_money_time_frames.length) {
      let valid = ar.assignment.mileage_money_time_frames.find(mmtf => 
        ar.startDate >= new Date(mmtf.starts_at) && ar.startDate <= new Date(mmtf.ends_at) ||
        ar.endDate   >= new Date(mmtf.starts_at) && ar.endDate   <= new Date(mmtf.ends_at)
      );
      if (valid) return await new Promise((res, rej) => {
        let angElem = angular.element('<mileage-money-popup valid="valid" license-plate="licensePlate" license-plate-country="licensePlateCountry" on-hide="onHide(ind)"></mileage-money-popup>');
        let newScope = this.$scope.$new(true);
        newScope.valid = valid;
        newScope.licensePlate = user.license_plate;
        newScope.licensePlateCountry = user.license_plate_country || 'DE';
        newScope.onHide = (ind) => {
          if (ind) {
            ar.mileage_money = true;
            this.loaderFactory.show();
            res(ar);
          }
          else throw new this.errFactory.QuietError;
        };
        this.loaderFactory.hide();
        angular.element(document).find('ng-view').append(this.$compile(angElem)(newScope));
      });
    }
    return ar;
  }

}

window.app.component('arPhototn', {
  template: require('scripts/components/time-trackings/activity-report-phototn/ar-phototn.html'),
  controller: ['API', 'ActivityReportFactory', 'AppFactory', 'AssignmentFactory', 'AuthService', 'LoaderFactory', 'ErrFactory', 'Notification', 'Config', '$scope', '$compile', ArPhototn]
});
