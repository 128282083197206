class SupportComponent {
  constructor (appVersion, AppFactory, API, ErrFactory, LoaderFactory, Notification) {
    Object.defineProperties(this, {
      appFactory: { value: AppFactory },
      api: { value: API },
      errFactory: { value: ErrFactory },
      notification: { value: Notification },
      loaderFactory: { value: LoaderFactory }
    });

    this.appVer = appVersion;
    this.device = {};
    this.loading = false;

    if (window.cordova && device) {
      this.device.manufacturer = device.manufacturer;
      this.device.model = device.model;
      this.device.platform = device.platform;
      this.device.version = device.version;
    }
  }

  submitForm() {
    this.loaderFactory.show();
    this.loading = true;
    let params = {
      device_info: {
          app_version: this.appVer,
          manufacturer: this.device.manufacturer,
          model: this.device.model,
          platform: this.device.platform,
          version: this.device.version
      },
      note: this.note
    };
    Promise.resolve(this.api.sendSupportRequestWithDeviceInfo(params))
    .then(() => this.loaderFactory.hide())
    .then(() => this.notification.alert({
      title: 'successfully',
      desc: 'dataSentSuccessfylly'
    }, () => this.appFactory.goBack()))
    .catch((err) => {
      this.loaderFactory.hide();
      if (err instanceof this.errFactory) err.notify(() => this.loading = false);
      else {
        console.log(err);
        this.loading = false;
      }
    })
  }

  goBack(event) {
    event.preventDefault();
    event.stopPropagation();
    this.appFactory.goBack();
  }

}

app.component('support', {
  template: require('scripts/components/support/support.html'),
  controller: SupportComponent
});
