class Sort {
  columns:  any;
  column:   string;
  callback: Function;
  constructor() { }

  sort(column: string): void {
    let sort = this.columns.find(c => c.sort === column);

    if (this.column === column) sort.reverse = !sort.reverse;
    else this.column = column;

    this.callback({ column, reverse: sort.reverse });
  }

}

window.app.component('sort', {
  template: require('scripts/components/sort/sort.html'),
  controller: [Sort],
  bindings: {
    callback: '&',
    columns:  '<',
    column:   '<'
  }
});
