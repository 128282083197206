class ActivityReportPreselected {
  TimeTrackingFactory:   any;
  AssignmentFactory:     any;
  ActivityReportFactory: any;
  LoaderFactory:         any;
  ErrFactory:            any;
  $location:             any;
  $scope:                any;
  $rootScope:            any;

  dateFormat:   string;
  dateWithWeek: string;
  column:       string;
  arList:       any[];
  _startDate:   Date;
  _endDate:     Date;
  
  constructor (TimeTrackingFactory, AssignmentFactory, ActivityReportFactory, LoaderFactory, ErrFactory, $location, $scope, $rootScope) {
    Object.defineProperty(this, 'TimeTrackingFactory', { value: TimeTrackingFactory });
    Object.defineProperty(this, 'AssignmentFactory', { value: AssignmentFactory });
    Object.defineProperty(this, 'LoaderFactory', { value: LoaderFactory });
    Object.defineProperty(this, 'ErrFactory', { value: ErrFactory });
    Object.defineProperty(this, 'ActivityReportFactory', { value: ActivityReportFactory });
    Object.defineProperty(this, '$location', { value: $location });
    Object.defineProperty(this, '$scope', { value: $scope });
    Object.defineProperty(this, '$rootScope', { value: $rootScope });

    let passed = localStorage.getItem('passedTutorials') ? JSON.parse(localStorage.getItem('passedTutorials')) : [];
    if (!passed.length || !passed.find(t => t === 'tracking')) $location.path('/tutorial/tracking');

    this.dateFormat = localStorage.getItem('date_format') || 'dd.MM.yyyy';
    this.dateWithWeek = `EEE.${this.dateFormat}`;
    this.column = 'start_date';
    this.arList = [];

     /**
     * Returns the week number for this date.  dowOffset is the day of week the week
     * "starts" on for your locale - it can be from 0 to 6. If dowOffset is 1 (Monday),
     * the week returned is the ISO 8601 week number.
     */
    (Date.prototype as any).getWeek = function (dowOffset) {
      dowOffset = typeof(dowOffset) == 'number' ? dowOffset : 0;
      var newYear = new Date(this.getFullYear(),0,1);
      var day = newYear.getDay() - dowOffset; //the day of week the year begins on
      day = (day >= 0 ? day : day + 7);
      var daynum = Math.floor((this.getTime() - newYear.getTime() - 
      (this.getTimezoneOffset()-newYear.getTimezoneOffset())*60000)/86400000) + 1;
      var weeknum;
      //if the year starts before the middle of a week
      if(day < 4) {
          weeknum = Math.floor((daynum+day-1)/7) + 1;
          if(weeknum > 52) {
              let nYear = new Date(this.getFullYear() + 1,0,1);
              let nday = nYear.getDay() - dowOffset;
              nday = nday >= 0 ? nday : nday + 7;
              /*if the next year starts before the middle of
                the week, it is week #1 of that year*/
              weeknum = nday < 4 ? 1 : 53;
          }
      }
      else {
          weeknum = Math.floor((daynum+day-1)/7);
      }
      return weeknum;
    };

    TimeTrackingFactory.getOwn()
    .then((list) => {
      this.createActivityReports(list);
    })
    .catch((err) => console.error(err))
    .then(() => $scope.$apply());
  }

  getStartOfFirstWeek(d) {
    d = new Date(d);
    d.setHours(0,0,0,0);
    let diff = d.getDate() - d.getDay() + (d.getDay() == 0 ? -6:1);
    return new Date(d.setDate(diff));
  }
  getEndOfLastWeek(d) {
    d = new Date(d)
    d.setHours(23,59,59,0);
    let diff = d.getDate() + (d.getDay() == 0 ? 0: 7 - d.getDay());
    return new Date(d.setDate(diff));
  }

  createActivityReports (dailyReportList) {
    dailyReportList.forEach( dr => dr.cw = dr.start_date.getWeek(1));
    let assignmentIds = dailyReportList.map(dr => dr.assignmentId).sort().filter(function(item, pos, ary) {
      return !pos || item != ary[pos - 1];
    })

    for (let i = 0; i < assignmentIds.length; i++) {
      let jobIdList = dailyReportList.filter(dr => dr.assignmentId == assignmentIds[i]);
      let cwList = jobIdList.map(dr => dr.cw).sort().filter(function(item, pos, ary) {
        return !pos || item != ary[pos - 1];
      });

      for (let j=0; j < cwList.length; j++) {
        let drList = jobIdList.filter(dr => dr.cw == cwList[j]);
        drList.sort((a, b) => {
          if (a.start_date > b.start_date) {return 1}
          if (a.start_date < b.start_date) {return -1}
          else { return 0 } 
        });

        this._startDate = this.getStartOfFirstWeek(drList[0].start_date);
        this._endDate = this.getEndOfLastWeek(drList[0].start_date);

        let ar = {
          assignment: drList[0].assignment,
          startDate: this._startDate,
          endDate: this._endDate,
          dailyReportList: drList
        }
        this.arList.push(ar)
      }
    }
    this.arList = this.arList.filter(ar => ar.assignment).sort((a, b) => a.startDate - b.startDate);
  }

  create (trackings) {
    if (trackings.assignment.starts_at > trackings.startDate)
      trackings.startDate = new Date(trackings.assignment.starts_at);
    if (trackings.assignment.ends_at && trackings.assignment.ends_at < trackings.endDate)
      trackings.endDate = new Date(trackings.assignment.ends_at);

    let ar = new this.ActivityReportFactory({
      assignment: trackings.assignment,
      startDate: trackings.startDate,
      endDate: trackings.endDate,
      dailyReportList: trackings.dailyReportList
    });

    ar.save()
    .then((ar) => {
      this.$location.path(`/activity-report/${ar.id}`);
      this.$scope.$apply();
    })
    .catch((err) => console.error(err));
  }


  back () {
    let to = `/`;
    if (this.$rootScope.ARbackLocation) {
      to = this.$rootScope.ARbackLocation;
      this.$rootScope.ARbackLocation = null;
    }
    this.$location.path(to);
  }

  goToManualTN () {
    this.LoaderFactory.show();
    return this.AssignmentFactory.getOwn()
      .then((jobs) => {
        if (jobs.length) return this.$location.path(`/activity-report/new`);
        else return Promise.reject(new this.ErrFactory.NoEBS)
      })
      .catch((err) => {
        if (err instanceof this.ErrFactory) err.notify();
        else console.error(err);
      })
      .then(() => this.LoaderFactory.hide())
  }
}

window.app.component('activityReportPreselected', {
  template: require('scripts/components/time-trackings/activity-report-preselected/activity-report-preselected.html'),
  controller: ['TimeTrackingFactory', 'AssignmentFactory', 'ActivityReportFactory', 'LoaderFactory', 'ErrFactory', '$location', '$scope', '$rootScope', ActivityReportPreselected]
});
